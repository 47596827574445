<template>
    <div class="classlist-box">
        <!-- 头部tab -->
        <!-- 面包屑 -->
        <div class="head-box container">
            <div class="crumbs">
                <i><img src="../assets/img/loginIcon/index-icon.png" alt=""></i><span>首页</span> ><span> 课程列表</span>
            </div>
             <div class="searchInput">
                <el-input
                placeholder="请输入您感兴趣的课程"
                clearable
                v-model="inputValue"
                @keyup.enter.native="toSearch"
                >
                    <el-button class="searchBtn"
                                size="mini"
                                slot="append"
                                icon="el-icon-search"
                                @click="toSearch"
                    ></el-button>
                </el-input>
            </div>
        </div>
        <!-- tab内容 -->
        <div class="tab-box">
            <div class="tab-slide">
                <p>课程分类：</p>
                <ul>
                    <span :class="{'active':activeId.categoryId == ''}" @click="addLabel('')" class="allspan">全部</span>
                    <li v-for="(item,id) in classification" :key="id">
                        <span :class="{'active':activeId.categoryId == item.id}" @click="addLabel(item.id)">{{item.name}}</span>
                    </li>
                </ul>
            </div>
            <div class="tab-slide">
                <p>课程收费：</p>
                <ul>
                   <li v-for="(item,index) in payList" :key="index">
                       <span :class="{'active':activePay.isPay == item.isPay}" @click="addPay(item.isPay)">{{item.name}}</span>
                   </li>
                </ul>
            </div>
        </div>
        <!-- 内容 -->
        <div class="course-content">
            <div class="content-wrap container">
                <div class="course-slide" v-for="(item,index) in classList" :key="index">
                    <!-- 封面 -->
                        <div class="img">
                            <img :src="item.logoUrl" alt="" v-if="item.logoUrl != null||item.logoUrl != undefined">
                            <img src="../assets/img/default_c.png" alt="" v-else>
                        </div>
                        <!-- 课程介绍 -->
                        <div class="text">
                            <div class="course-name">
                                <p>课程名称：<a href="javascript:;" @click="jumpClass(item.id)">{{item.name}}</a></p>
                            </div>
                            <div class="course-teacher">
                                <p>授课老师：<span>{{item.teacherName}}</span></p>
                            </div>
                            <div class="course-price">
                                <p v-if="item.isPay == 0">课程价格：
                                    <span class="discount">免费</span>
                                </p>
                                <p v-else>课程价格：
                                    <span class="discount">¥{{item.price}}</span>
                                    <label v-if="item.originalPrice == null">
                                        <span class="full"></span>
                                    </label>
                                    <label v-else>
                                        <span class="full">¥{{item.originalPrice}}</span>
                                    </label>
                                </p>
                            </div>
                            <div class="course-introduce">
                                <p>课程介绍：<span>{{item.intro}}</span></p>
                            </div>
                        </div>
                        <div class="button">
                            <div v-if="item.isBuy == 0">
                                <div class="get-cart">
                                    <p @click="addCart(item.id)">
                                        加入购物车
                                    </p>
                                </div>
                                <div class="buy">
                                    <p @click="buy(item.id)">
                                        立即购买
                                    </p>
                                </div>
                            </div>
                            <div v-else>
                                <div class="get-check">
                                    <a href="javascript:;" @click="jumpClass(item.id)">
                                        查看课程
                                    </a>
                                </div>
                            </div>
                        </div>
                </div>
                <!-- 分页 -->
                <div class="page">
                    <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[5, 10, 20, 40]"
                    :page-size="classPagination.pageSize"
                    layout="total, prev, pager, next"
                    :total= "total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'classlist',
    data(){
        return {
            total: 0,
            currentPage: 1,
            classPagination: {},
            // 分类数据
            classification: [],
            orderList: [],
            // 装着筛选的数据
            // addClass: [],
            classList: [],
            activeId: {
                categoryId: ''
            },
            activePay: {
                isPay: ''
            },
            // 课程是否收费
            payList: [
                {
                    "isPay": '',
                    "name": "全部"
                },
                {
                    "isPay": '0',
                    "name": "免费"
                },
                {
                    "isPay": 1,
                    "name": "收费"
                }
            ],
            setClassification: '',
            setPay: '',
            inputValue: ''
        }
    },
    mounted() {
        this.getCategory();
        this.getClassList();
    },
    metaInfo: {
        title: '课程中心-课程列表-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    // computed: {
    //     classArr() {
    //         return Object.keys(this.activeClick).filter(str => {
    //             console.log(this.activeClick)
    //             if(this.activeClick[str] !== '') {
    //                 return true
    //             }
    //         })
    //     }
    // },
    methods: {
        // 加入购物车
        addCart(id) {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/cart/add.html',
                data: {
                    courseId: id
                }
            }).then((res) => {
                console.log(res);
                if(res.data.code == 1000) {
                    this.$message.warning(res.data.msg);
                } else if(res.data.code == 1503){
                    this.$message.warning(res.data.msg);
                } else if(res.data.code == 1002){
                    this.$message.warning("请前往登录账号");
                } else {
                    this.$message.success("已添加至购物车");
                    this.$store.commit('saveCartCount',res.data.result.count);
                }
            })
        },
        // 立即购买
        buy(id) {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/cart/add.html',
                data: {
                    courseId: id
                }
            }).then((res) => {
                if(res.data.code == 1000) {
                    this.$message.warning(res.data.msg);
                } else if(res.data.code == 1503) {
                    this.$store.commit('saveCartCount',res.data.result.count);
                    this.$router.push({
                        path: '/cart'
                    })
                } else if(res.data.code == 1002){
                    this.$message.warning("请前往登录账号");
                }else {
                    this.$message.success("已添加至购物车");
                    this.$router.push({
                        path: '/cart'
                    })
                }
            })
        },
        handleCurrentChange(val) {
            // console.log(currentPage);
            this.currentPage = val;
            this.getClassList();
        },
        // 获取筛选条件
        addLabel(val) {
            this.activeId.categoryId = val;
            this.getClassList();
        },
        addPay(val) {
            this.activePay.isPay = val;
            this.getClassList();
        },
        // 筛选列表
        // filterClassList() {
        //     this.addClass = this.classList.filter(a =>{
        //         return this.classArr.every(item => {
        //              return this.activeClick[item] == a[item];
        //         })
        //     })
        //     this.total = this.addClass.length;
        //     this.currentPage = 1;
        // },
        // 获取分类的数据
        getCategory() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/course/category.html'
            }).then((res) => {
                this.classification = res.data.result.list;
            })
        },
        // 获取列表
        getClassList() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/course/page.html',
                data: {
                    pageNum: this.currentPage,
                    categoryId: this.activeId.categoryId,
                    isPay: this.activePay.isPay
                }
            }).then((res) => {
                this.classPagination = res.data.result;
                this.classList = res.data.result.list;
                this.total = res.data.result.totalCount;
            })
        },
        toSearch() {
            if (this.inputValue == '') return this.$message.error("搜索关键字为空!")
            // this.$store.commit("setSearch", this.inputValue);
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/course/page.html',
                data: {
                    pageNum: this.currentPage,
                    categoryId: this.activeId.categoryId,
                    isPay: this.activePay.isPay,
                    searchKey: this.inputValue
                }
            }).then((res) => {
                this.classPagination = res.data.result;
                this.classList = res.data.result.list;
                this.total = res.data.result.totalCount;
                // this.inputValue = '';
            })
            
        },
        jumpClass(id) {
            this.$router.push({
                name:'classdetail',
                params: {
                    "id":id
                }
            })
        }
    }

}
</script>
<style lang="scss">
    
    .classlist-box {
        margin-top: 70px;
        padding-bottom: 12px;
        width: 100%;
        min-width: 1200px;
        background: #f2f2f2;
        .container {
            margin: 0 auto;
            width: 1200px;
        }
        // 头部
        .head-box {
            padding-top: 70px;
            margin-bottom: 40px;
            height: 48px;
            display: flex;
            justify-content: space-between;
            .crumbs {
                font-size: 14px;
                color: #666666;
                font-family: Microsoft YaHei;
                line-height: 50px;
                i {
                    display: inline-block;
                    margin-right: 5px;
                    width: 14px;
                    height: 14px;
                }
            }
            .searchInput {
                width: 431px;
                .el-input__inner {
                    width: 343px;
                    height: 48px;
                    border: 1px solid #FF5A74;
                    border-radius: 5px 0px 0px 5px;
                }
                .el-input-group__append {
                    padding: 0 41px;
                    background: #FF5A74;
                    border-radius: 0px 5px 5px 0px;
                    border: 1px solid #FF5A74;
                    .el-icon-search {
                        color: #ffffff;
                    }
                }
            }
        }
        .tab-box {
            margin: 0 auto;
            width: 1200px;
            height: 120px;
            border: 1px solid #DEDEDE;
            .tab-slide {
                padding-left: 13px;
                box-sizing: border-box;
                display: flex;
                height: 60px;
                align-items: center;
                border-bottom: 1px dashed #DEDEDE;

                &:last-child {
                    border-bottom: none;
                }
                .allspan {
                    margin-right: 30px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    cursor: pointer;
                }
                p {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                }
                ul {
                    display: flex;
                    align-items: center;
                    .active {
                        color: #FA4B66;
                    }
                    li {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        cursor: pointer;
                        .active {
                            color: #FA4B66;
                        }
                        span {
                            margin-right: 30px;
                            // &:last-child {
                            //     margin-right: 0;
                            // }
                        }
                    }
                }
            }
        }
        .course-content {
            margin-top: 20px;
            .page {
                text-align: center;
            }
            .content-wrap {
                .course-slide {
                    display: inline-block;
                        padding-top: 20px;
                        padding-left: 20px;
                        padding-right: 20px;
                        margin-bottom: 20px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: space-between;
                        height: 250px;
                        background: #FFFFFF;
                        .img {
                            width: 380px;
                            height: 210px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .text {
                            width: 595px;
                            .course-name {
                                height: 53px;
                                line-height: 53px;
                                border-bottom: 1px #eee dashed;
                                p {
                                    font-size: 16px;
                                    color: #999999;
                                }
                                a {
                                    color: #333333;
                                    font-size: 20px;
                                    font-weight: bold;
                                }
                            }
                            .course-teacher {
                                height: 53px;
                                line-height: 53px;
                                border-bottom: 1px #eee dashed;
                                font-size: 16px;
                                p {
                                    color: #999999;
                                }
                                span {
                                    color: #333333;
                                }
                            }
                            .course-price {
                                height: 53px;
                                line-height: 53px;
                                border-bottom: 1px #eee dashed;
                                p {
                                    font-size: 16px;
                                    color: #999999;
                                }
                                .discount {
                                    margin-right: 19px;
                                    color: #FA4B66;
                                    font-size: 20px;
                                    font-weight: bold;
                                }
                                .full {
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    text-decoration: line-through;
                                    color: #999999;
                                }
                            }
                            .course-introduce {
                                height: 53px;
                                line-height: 53px;
                                font-size: 16px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                p {
                                    color: #999999;
                                }
                                span {
                                    color: #333333;
                                }
                            }
                        }
                        .button {
                            margin-top: 55px;
                            .get-cart {
                                margin-bottom: 31px;
                                cursor: pointer;
                                width: 120px;
                                height: 40px;
                                line-height: 40px;
                                background: #FA4B66;
                                border-radius: 4px;
                                text-align: center;
                                p {
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    color: #FFFFFF;
                                }
                            }
                            .buy {
                                margin-bottom: 31px;
                                cursor: pointer;
                                width: 120px;
                                height: 40px;
                                line-height: 40px;
                                border: 1px solid #FA4B66;
                                background: #FFFFFF;
                                border-radius: 4px;
                                text-align: center;
                                p {
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    color: #FA4B66;
                                }
                            }
                            .get-check {
                                margin-top: 40px;
                                cursor: pointer;
                                width: 120px;
                                height: 40px;
                                line-height: 40px;
                                border: 1px solid #FA4B66;
                                background: #FFFFFF;
                                border-radius: 4px;
                                text-align: center;
                                a {
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    color: #FA4B66;
                                }
                            }
                        }
                }
            }
        }
    }
</style>